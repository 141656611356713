import { Avatar, Badge, Box, Button, Card, Group, Text } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import dayjs from "dayjs";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

function isEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const JobListItem = ({ job, isOtherJob }) => {
  const { hovered, ref } = useHover();
  const router = useRouter();
  const handleClick = (e) => {
    if (isOtherJob) {
      handleApplyClick(e);
    } else {
      router.push(`/job/${job.slug}`);
    }
  };
  const handleApplyClick = (e) => {
    e.stopPropagation();
  };

  let jobUrl;
  if (isEmail(job.application_url)) {
    jobUrl = "mailto:" + job.application_url + `?subject=${job.title} - ReactNativeJobs.com`;
  } else {
    try {
      jobUrl = new URL(job.application_url);
      jobUrl.searchParams.append("ref", "reactnativejobs.com");
    } catch (err) {
      jobUrl = job.application_url;
    }
  }

  return (
    <div ref={ref}>
      <Box component="a" href={jobUrl} target="_blank">
        <Card
          radius="md"
          withBorder
          p="md"
          mb="md"
          onClick={handleClick}
          sx={(theme) => ({
            [theme.fn.largerThan("sm")]: {
              display: "flex",
            },
            justifyContent: "space-between",
            alignItems: "center",
            borderColor: job.is_featured ? theme.colors.yellow[6] : theme.colors.gray[2],
          })}
        >
          <Box sx={(theme) => ({ display: "flex", alignItems: "center" })}>
            <Avatar size="lg" radius={100} src={job.logo_url} name={job.company}>
              {job.company?.[0]}
            </Avatar>
            <Box
              sx={(theme) => ({
                padding: 8,
              })}
            >
              <Text weight="500">{job.company}</Text>
              <Text weight="bold" lineClamp={1}>
                {job.title}
              </Text>
              <Group>
                {job.job_type && <Text size={"sm"}>{job.job_type}</Text>}
                <Text size="sm">{job.location}</Text>
              </Group>
            </Box>
          </Box>

          <Group
            sx={(theme) => ({
              [theme.fn.smallerThan("sm")]: {
                justifyContent: "space-between",
                alignItems: "center",
              },
            })}
          >
            {!isOtherJob && (
              <Button
                role="link"
                target="blank"
                variant="outline"
                href={`/job/${job.slug}`}
                sx={(theme) => ({
                  display: hovered ? "block" : "none",
                  [theme.fn.smallerThan("sm")]: {
                    display: "none",
                  },
                })}
              >
                Details
              </Button>
            )}
            <Box component="a" href={jobUrl} target="_blank">
              <Button
                variant="outline"
                //   href={job.application_url + "?ref=reactnativejobs.com"}
                onClick={handleApplyClick}
                sx={(theme) => ({
                  visibility: hovered ? "visible" : "hidden",
                  [theme.fn.smallerThan("sm")]: {
                    visibility: "visible",
                  },
                })}
              >
                Apply
              </Button>
            </Box>

            <Badge sx={{ display: job.is_featured ? "block" : "none" }} variant="filled" color={"yellow"}>
              Featured
            </Badge>

            <Text>{dayjs(job.approved_at).format("MMM DD")}</Text>
          </Group>
        </Card>
      </Box>
    </div>
  );
};

export default JobListItem;
