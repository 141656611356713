import { Box, Title } from "@mantine/core";
import React from "react";

const Banner = () => {
  return (
    <Box>
      <Title order={2} align="center" py="xl">
        Find Your Next React Native Job
      </Title>
    </Box>
  );
};

export default Banner;
